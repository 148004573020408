<template>
  <header
    class="header"
    :class="{ 'scoll': isScroll }"
  >
    <div
      v-if="$viewport.isGreaterOrEquals('lg')"
      class="header__top"
    >
      <div class="container">
        <div class="header__additional-menu">
          <template
            v-for="(_item, index) in additionalMenuItems"
            :key="`additionalMenuItems-${ index }`"
          >
            <nuxt-link :to="localePath(`/${_item.path}`)">{{ _item?.title }}</nuxt-link>
          </template>
        </div>
      </div>
    </div>
    <div class="header__middle">
      <div class="container">
        <div class="header__main-content">
          <nuxt-link
            :to="localePath('/')"
            class="--logo"
          >
            <img
              :src="logoImage"
              alt="logo"
            />
          </nuxt-link>

          <div
            v-if="$viewport.isGreaterOrEquals('lg')"
            class="--menu"
          >
            <MainMenu :items="mainMenuItems" />
          </div>

          <div class="--controls">
            <TopControls @menuClick="mobileSidebarOpen" />
          </div>
        </div>
      </div>
    </div>

    <template v-if="$viewport.isGreaterOrEquals('lg')">
      <div class="container">
        <div class="header__bottom">
          <UserMenu :is-auth="isUserAuth"/>

          <template v-if="!isUserAuth">
            <div class="--buttons">
              <NuxtLink
                :to="localePath('/authorization')"
                class="btn btn-primary --auth"
              >{{$t('authorization.title')}}</NuxtLink>
              <NuxtLink
                :to="localePath('/registration')"
                class="btn btn-primary"
              >{{$t('registration.title')}}</NuxtLink>
            </div>
          </template>
          <template v-if="isUserAuth">
            <div class="--right-content">
              <div class="--balance">
                <span>{{ balanceLabel }}</span>
                <div class="--button">
                  {{$t('header.replenish')}}
                </div>
              </div>
              <div class="btn btn-primary --exit" @click="exitUser">
                {{$t('common.exit')}}
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <template v-if="!$viewport.isGreaterOrEquals('lg')">
      <HeaderSidebarV2
        v-show="mobileSidebarIsOpen"
        :additional-menu-items="additionalMenuItems"
        :main-menu-items="mainMenuItems"
        @menuClick="mobileSidebarClose"
      />
      <UserMobileMenu />
    </template>
  </header>
</template>

<script lang="ts" setup>
import type { ComputedRef } from 'vue';
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import MainMenu from '~/components/header/MainMenuV2.vue';
import UserMenu from '~/components/header/UserMenuV2.vue';
import TopControls from '~/components/header/TopControlsV2.vue';
import UserMobileMenu from '~/components/header/UserMobileMenuV2.vue';
import HeaderSidebarV2 from '~/components/header/HeaderSidebarV2.vue';
import { useSmsUser } from '~/store/sms-user';

const {
  $viewport,
  $numberFormat
} = useNuxtApp();

const smsUser = useSmsUser();
const sitisAuth = useSitisAuth();
const localePath = useLocalePath();
const cmsStore = cmsContentStore();
const appConfig = useAppConfigStore();

const mainMenuItems: ComputedRef<any[]> = computed(() => {
  return [...(cmsStore.$state.menu || [])]
    .filter((t: any) => {
      return Boolean(!t.isHome && !t.isHidden);
    });
});
const additionalMenuItems: ComputedRef<any[]> = computed(() => {
  const _list = cmsStore.$state?.menus?.['additional-menu']?.items || [];
  return _list.filter((t: any) => {
    return Boolean(!t.isHome && !t.isHidden);
  });
});

const logoImage: ComputedRef<string> = computed(() => {
  return appConfig?.$state?.config?.logoBack;
});

const isScroll: Ref<boolean> = ref(false);
const eventScroll = () => {
  isScroll.value = Boolean(window.scrollY > 100);
};
onMounted(() => {
  window.addEventListener('scroll', eventScroll);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', eventScroll);
  document.body.classList.remove('scroll-hidden-mobile');
});

const mobileSidebarIsOpen = ref(false);

const mobileSidebarOpen = () => {
  mobileSidebarIsOpen.value = true;
  document.body.classList.add('scroll-hidden-mobile');
};
const mobileSidebarClose = () => {
  mobileSidebarIsOpen.value = false;
  document.body.classList.remove('scroll-hidden-mobile');
};

const isUserAuth: ComputedRef<boolean> = computed(() => {
  return sitisAuth.isLoggedIn.value
});
const exitUser = async () => {
  sitisAuth.logOut();
};
const balanceLabel: ComputedRef<string> = computed(() => {
  let _balance = $numberFormat(smsUser.balance);
  return [_balance, '₽'].join(' ')
});
</script>

<style lang="scss">
.header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: fixed;
  padding: 4px;
  box-sizing: border-box;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  background: hsla(var(--white)/1);
  transition: gap 0.1s;

  &.scoll {
    gap: 0;
  }
}

.header__top {
  background: hsla(var(--black)/0.1);
  border-radius: 8px;
}

.header__middle {}

.header__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: hsla(var(--link-tx)/0.2);
  padding: 10px;
  padding-left: 16px;
  box-sizing: border-box;
  border-radius: 10px;
  min-height: 56px;

  .--buttons {
    display: flex;
    gap: 6px;
  }
  .--buttons .--auth {
    background: hsla(var(--primary-bg)/0.6);
    border-color: hsla(var(--primary-bg)/0.6);
  }

  .--right-content {
    display: flex;
    align-items: center;
  }
  .--balance {
    display: flex;
    align-items: center;
    padding: 4px;
    padding-left: 12px;
    box-sizing: border-box;
    background-color: hsla(var(--primary-bg)/1);
    border-radius: 10px;

    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: -0.02em;
    color: hsla(var(--white)/1);
  }
  .--balance span {
    min-width: 80px;
    text-align: center;
  }
  .--balance .--button {
    padding: 6px 12px;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 12px;
    background-color: hsla(var(--white)/0.2);

    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    color: hsla(var(--white)/1);
    letter-spacing: -0.02em;
  }
  .--exit {
    background-color: hsla(var(--primary-bg)/1);
    border-color: hsla(var(--primary-bg)/1);
    margin-left: 6px;
    font-weight: 600;
  }
}

.header__additional-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;

  a {
    font-size: 13px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: hsla(var(--dark-gray-tx)/1);

    &:hover {
      text-decoration: underline;
    }
  }
}

.header__main-content {
  display: flex;
  align-items: center;

  .--logo {
    display: flex;
    align-items: center;
    height: 56px;
    max-width: 265px;
  }

  .--logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .--menu {
    margin: 0 auto;
  }

  .--controls {}
}

@media (max-width: 1399px) {
  .header {
    gap: 8px;
  }
}

@media (max-width: 1199px) {
  .header__main-content {
    justify-content: space-between;
    gap: 24px;

    .--logo {
      max-width: 185px;
      max-height: 40px;
    }
  }
}
</style>
